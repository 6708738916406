<template>
    <main class="mb-5">
        <div id="up" class="w-100">
            <div class="col-12 mb-2 text-center">
                <h1 class="h2">
                    Venta en
                    <span class="text-primary text-capitalize">
                        {{
                            selectedMerchant.merchantType | merchantInfo("type")
                        }}
                        {{ selectedMerchant.name }}
                    </span>
                    {{ sourcePriceMerchant ? "" : `con precios ${$siteType}` }}
                </h1>
            </div>
        </div>

        <div v-if="isOnboarding" class="w-100 my-3 text-justify">
            <span>
                Finalmente, registra una venta. Ve al buscador de productos de
                la parte inferior y escribe de nuevo
                <strong> cuaderno </strong> o el nombre del producto que
                ingresaste, es importante hacer referencia a este producto, ya
                que el buscador solo buscará en tu inventario. Si NO recuerdas
                el producto que ingresaste, registralo de nuevo en el inventario
                dando click
                <router-link to="/onboarding/inventories"> aqui </router-link>.
                <br />
                <br />
                Cuando tengas los productos seleccionados da click en el botón
                "Crear Venta" el sistema registrará la venta, decrementará las
                unidades de tu inventario e incrementará el dinero disponible en
                la caja.
            </span>
        </div>

        <div
            class="w-100 d-flex flex-wrap flex-column-reverse flex-md-row justify-content-between align-items-center my-2"
        >
            <component
                :is="
                    sourcePriceMerchant
                        ? 'inventories-barcode-scanner'
                        : 'products-barcode-scanner'
                "
                v-model="enabledScanner"
                :enabled="!isEnabledServiceSearch"
                @input="selectProduct"
            />
            <div class="d-flex align-items-center justify-content-between">
                <tooltip-button
                    icon="ℹ️"
                    message="¿ Necesitas ayuda para registrar ventas ?"
                    @click="enableHelp = !enableHelp"
                />
                <tooltip-button
                    v-if="!isOnboarding"
                    icon="⚙"
                    message="Opciones"
                    @click="enableConfig = !enableConfig"
                />
            </div>
        </div>
        <div>
            <div class="w-100 d-flex flex-wrap">
                <div
                    v-show="sourcePriceMerchant && enableHelp"
                    class="col-10 mx-auto rounded border border-success pt-3"
                >
                    <span>
                        para registrar tus ventas sigue las siguientes
                        instrucciones :
                    </span>
                    <ul class="list-group">
                        <li class="py-2 px-1">
                            1. Busca los productos que deseas agregar. Puedes
                            utilizar el buscador ubicado en la parte inferior,
                            escanear el código de barras correspondiente, o
                            cambiar al modo "servicios" y buscar el servicio que
                            deseas registrar.
                        </li>
                        <li class="py-2 px-1">
                            2. Selecciona el medio de pago con el que se
                            realizará la trasacción.
                        </li>
                        <li class="py-2 px-1">
                            3. Verifica que el total esté correcto y haz clic en
                            el botón "Crear venta", el cual se desplegará
                            únicamente si hay productos seleccionados.
                        </li>
                        <li class="py-2 px-1">
                            4. Puedes aplicar valores de descuento por producto
                            haciendo click en el checkbox amarillo de la lista
                            de productos de la orden. El calculo del total a
                            pagar se calcula de la siguiente forma: SUMA (
                            (Precio de la referencia - Descuento por unidad ) *
                            Cantidad de Stock)
                        </li>
                        <li class="py-2 px-1">
                            5. Si deseas crear una orden con un estado de
                            progreso, debes habilitar el pago parcial.
                        </li>
                    </ul>
                </div>

                <div
                    v-show="!sourcePriceMerchant && enableHelp"
                    class="col-10 mx-auto rounded border border-success pt-3"
                >
                    <span>
                        En esta sección, tienes la posibilidad de registrar
                        pedidos con el estado de entrega completado, lo que
                        implica una transacción de venta para la tienda. La
                        particularidad radica en el uso de precios predefinidos
                        en el {{ $siteType }} y el filtrado en todos los
                        productos disponibles en la plataforma. En caso de
                        necesitar utilizar los precios específicos del comercio,
                        te recomendamos utilizar la sección "Venta en comercio".
                    </span>
                    <ul class="list-group">
                        <li class="py-2 px-1">
                            1. Busca los productos. Puedes usar el buscador de
                            la parte inferior, escanear el código de barras o
                            ingresar el monto del servicio que se va a
                            registrar. El buscador filtrará entre todos los
                            productos del {{ $siteType }}. Puede econctrar
                            productos que no tengas en el inventario, en este
                            caso el sistema te informará que producto debes
                            registrar en el inventario.
                        </li>
                        <li class="py-2 px-1">
                            2. Selecciona el medio de pago con el que se
                            realizará la trasacción.
                        </li>
                        <li class="py-2 px-1">
                            3. Verifica que el total esté correcto y haz clic en
                            el botón "Crear venta", el cual se desplegará
                            únicamente si hay productos seleccionados.
                        </li>
                        <li class="py-2 px-1">
                            4. Puedes aplicar valores de descuento por producto
                            haciendo click en el checkbox amarillo de la lista
                            de productos de la orden. El calculo del total a
                            pagar se calcula de la siguiente forma: SUMA (
                            (Precio de la referencia - Descuento por unidad ) *
                            Cantidad de Stock)
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div v-show="enableConfig">
            <div class="w-100 d-flex flex-wrap flex-md-row flex-column mt-3">
                <div
                    v-if="$ability.hasScope('customer:List')"
                    class="col-12 col-md-4"
                >
                    <vs-checkbox v-model="saleNeedCustomer" success>
                        <span>
                            {{
                                customerName && saleNeedCustomer
                                    ? customerName
                                    : "Asociar venta a cliente"
                            }}
                        </span>
                    </vs-checkbox>
                </div>
                <div
                    v-if="$ability.hasScope('user:ListOwn')"
                    class="col-12 col-md-4"
                >
                    <vs-checkbox v-model="saleNeedSeller" success>
                        <span>
                            {{
                                sellerName && saleNeedSeller
                                    ? sellerName
                                    : "Asociar venta a vendedor"
                            }}
                        </span>
                    </vs-checkbox>
                </div>
                <div class="col-12 col-md-4">
                    <vs-checkbox v-model="saleNeedComments" warn>
                        <span v-if="saleNeedComments && comments">
                            {{ comments | cut(20) }}
                        </span>
                        <span v-else> Comentarios adicionales </span>
                    </vs-checkbox>
                </div>
                <div class="col-12 col-md-4">
                    <vs-checkbox v-model="saleNeedPartialPaid" warn>
                        <span v-if="saleNeedPartialPaid">
                            Pago parcial de {{ valuePaid | money }}
                        </span>
                        <span v-else> Pago parcial </span>
                    </vs-checkbox>
                </div>
                <div class="col-12 col-md-4">
                    <vs-checkbox v-model="notVerifyAndDecreaseInventory" warn>
                        <span> No verificar ni decrementar inventario </span>
                    </vs-checkbox>
                </div>
            </div>
        </div>

        <div class="d-flex flex-md-column flex-column-reverse">
            <div class="w-100 d-flex flex-wrap flex-md-row flex-column-reverse">
                <div
                    class="col-12 col-md-8 p-md-0 d-flex flex-column justify-content-center"
                >
                    <h2 class="mt-1 mb-0 h5">
                        {{
                            isEnabledServiceSearch
                                ? "Selecciona servicios"
                                : "Selecciona productos"
                        }}
                    </h2>
                    <div class="w-100 d-flex align-items-end mt-2">
                        <service-selector
                            v-if="isEnabledServiceSearch"
                            :base="selectedMerchant.services"
                            @input="selectProductAnonymous"
                        />
                        <component
                            :is="
                                sourcePriceMerchant
                                    ? 'inventories-search'
                                    : 'products-search'
                            "
                            v-else
                            v-model="lastProductSelected"
                            @focus="onFocusSomeInput"
                            @blur="onBlurSomeInput"
                            @input="selectProduct"
                        />
                        <vs-tooltip border>
                            <vs-button icon @click="toggleIsEnabledServices">
                                {{ isEnabledServiceSearch ? "🛍" : "👩‍🏭" }}
                            </vs-button>
                            <template #tooltip>
                                <div class="content-tooltip">
                                    <span>
                                        {{
                                            isEnabledServiceSearch
                                                ? "Cambia a buscador de productos"
                                                : "Cambia a buscador de servicios"
                                        }}
                                    </span>
                                </div>
                            </template>
                        </vs-tooltip>
                        <product-anonymous-button
                            @input="selectProductAnonymous"
                        />
                    </div>
                </div>
                <div class="col-12 col-md-4 p-md-0 pl-md-3">
                    <payment-methods-checker v-model="paymentMethod">
                        <template #title>
                            <span class="font-weight-bold">Método de pago</span>
                        </template>
                    </payment-methods-checker>
                </div>
            </div>
        </div>

        <div class="z-0 mt-3 h-min-24">
            <h4 v-show="references.length">Referencias seleccionadas</h4>
            <reference-table-item
                v-for="(reference, index) in references"
                :key="index"
                :reference="reference"
                enabled-discount
                enabled
                class="col-md-11 col-12 mx-auto"
                @update="changeQuantityReference"
                @remove="removeReference"
                @focus="onFocusSomeInput"
            />
            <h4 v-show="anonymousReferences.length" class="mt-3">
                Servicios / Referencias anónimas
            </h4>
            <reference-table-item
                v-for="(reference, index) in anonymousReferences"
                :key="'B' + index"
                :reference="reference"
                class="col-md-11 col-12 mx-auto"
                enabled
                @update="changeQuantityAnonymousReference"
                @remove="removeAnonymousReference"
                @focus="onFocusSomeInput"
            />
        </div>

        <div v-if="total > 0" class="w-100 d-flex flex-wrap z-0 mt-4 mb-5">
            <div
                class="col-11 mx-auto d-flex flex-lg-row flex-column justify-content-end"
            >
                <div class="d-flex flex-column">
                    <h5>Subtotal : {{ subtotal | money }}</h5>
                    <h4 class="text-success">Total : {{ total | money }}</h4>
                </div>
            </div>
            <vs-button
                id="create-order"
                class="mt-3 mb-5 col-9 mx-auto"
                border
                gradient
                @click="createSaleComponent"
            >
                <span class="h3"> Crear venta </span>
            </vs-button>
        </div>

        <div
            v-if="isOnboarding"
            class="d-flex justify-content-center my-3 link-secondary"
        >
            <router-link to="/app"> Omitir </router-link>
        </div>

        <div id="bottom"></div>

        <vs-dialog
            v-model="isOpenModalReferences"
            scroll
            overflow-hidden
            auto-width
            @close="enabledScanner = true"
        >
            <template #header>
                <div
                    class="mx-4 mt-2 d-flex flex-column flex-lg-row align-items-center"
                >
                    <span class="h4">
                        Seleccione la referencia del producto
                        <span class="text-primary">
                            {{ productTmp.name }}
                        </span>
                    </span>
                </div>
            </template>
            <div class="con-content">
                <div class="d-flex flex-wrap w-100">
                    <div
                        v-for="(item, index) in productTmp.references"
                        :key="item.id"
                        class="col-10 col-md-5 col-lg-3 mx-auto p-2"
                    >
                        <button
                            class="d-flex flex-column btn btn-primary w-100"
                            :class="
                                referenceSelected === index
                                    ? 'border-3 border-highlight'
                                    : ''
                            "
                            @click="selectReference(index)"
                        >
                            <div>
                                <strong> Tamaño/Talla/Valor </strong>
                                <span class="text-capitalize">
                                    {{ item.size }}
                                </span>
                            </div>
                            <div class="text-capitalize">
                                <strong> Color </strong>
                                <span>
                                    {{ item.color }}
                                </span>
                            </div>
                            <div>
                                <strong> Precio </strong>
                                <span>
                                    {{ item.priceOffer | money }}
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </vs-dialog>

        <vs-dialog
            v-model="isOpenModalSellers"
            scroll
            overflow-hidden
            auto-width
            blur
            @close="saleNeedSeller = !!sellerId"
        >
            <template #header>
                <div class="mx-4 mt-2 d-flex w-80vw">
                    <h3 class="text-center">Seleccione vendedor 👩‍💼</h3>
                </div>
            </template>
            <div class="con-content">
                <user-by-merchant-selector-2
                    v-if="isOpenModalSellers"
                    :merchant="selectedMerchant"
                    @change="selectSeller"
                />
            </div>
        </vs-dialog>

        <vs-dialog
            v-model="isOpenModalCustomers"
            scroll
            overflow-hidden
            auto-width
            blur
            @close="saleNeedCustomer = !!customerId"
        >
            <template #header>
                <div class="mx-4 mt-2 d-flex w-80vw">
                    <h3 class="text-center">Buscador de clientes 👩‍🎤</h3>
                </div>
            </template>

            <search-user
                is-customer
                @change="selectCustomer"
                @focus="onFocusSomeInput"
                @blur="onBlurSomeInput"
            />
        </vs-dialog>

        <vs-dialog
            v-model="isOpenModalValuePaid"
            scroll
            overflow-hidden
            auto-width
            @close="enabledScanner = true"
        >
            <template #header>
                <div
                    class="mx-4 mt-2 d-flex justify-content-center align-items-center flex-column flex-lg-row"
                >
                    <span class="h4"> Pago parcial </span>
                    <vs-button icon circle @click="selectValuePaid">
                        ✔️
                    </vs-button>
                </div>
            </template>
            <div class="con-content">
                <div class="mx-3 my-4">
                    <input-money-component
                        v-model="valuePaid"
                        border
                        label="Pago parcial"
                        icon="💲"
                    />
                </div>
            </div>
        </vs-dialog>

        <vs-dialog
            v-model="isOpenModalComments"
            scroll
            overflow-hidden
            auto-width
            @close="enabledScanner = true"
        >
            <template #header>
                <div
                    class="mx-4 mt-2 d-flex justify-content-center align-items-center flex-column flex-lg-row"
                >
                    <span class="h4"> Comentarios adicionales </span>
                    <vs-button icon circle @click="selectComments">
                        ✔️
                    </vs-button>
                </div>
            </template>
            <div class="con-content">
                <div class="mx-3">
                    <textarea
                        v-model="comments"
                        class="vs-textarea text-base mx-2 mb-2"
                        placeholder="Comentarios"
                        rows="5"
                    ></textarea>
                </div>
            </div>
        </vs-dialog>

        <vs-dialog
            v-model="isOpenModalResult"
            scroll
            overflow-hidden
            @close="closeResult"
        >
            <template #header>
                <div class="mx-4 mt-2 d-flex flex-column flex-lg-row">
                    <span class="h3"> El dinero ingresado debe ser </span>
                </div>
            </template>
            <div class="con-content">
                <div
                    class="d-flex flex-column justify-content-center align-items-center"
                >
                    <span class="text-primary display-3">
                        {{ moneyIngress | currency }}
                    </span>
                    <div></div>
                    <div v-if="moneyBack" class="d-flex flex-column text-xl">
                        <span class="">
                            Vuelto de:
                            <strong>
                                {{ moneyBack | currency }}
                            </strong>
                        </span>
                        <span class="">
                            Venta de:
                            <strong>
                                {{ saleResult.total | currency }}
                            </strong>
                        </span>
                    </div>
                </div>
                <div class="mt-4 d-flex justify-content-center">
                    <vs-button @click="showOrderPrinter = !showOrderPrinter">
                        🖨 Vale de compra
                    </vs-button>
                    <vs-button v-if="!isOnboarding" @click="closeResult">
                        💰 Registrar venta
                    </vs-button>
                    <vs-button
                        v-if="!isOnboarding"
                        :to="
                            $enabledEcommerceOptions
                                ? `/app/orders/${selectedMerchant.merchantType}/${selectedMerchant.merchantId}/detail/${saleResult.id}`
                                : `/app/orders/detail/${saleResult.id}`
                        "
                    >
                        🐱‍💻 Detalles
                    </vs-button>
                </div>

                <div v-if="showOrderPrinter" class="mt-5">
                    <order-printer show :order="saleResult" />
                </div>
            </div>
        </vs-dialog>

        <vs-dialog
            v-model="isOpenModalMoneyBack"
            blur
            scroll
            overflow-hidden
            prevent-close
            @close="onEnterMoneyIngress"
        >
            <template #header>
                <div class="mx-4 mt-2 d-flex flex-column flex-lg-row">
                    <span class="h4">
                        Valor de venta {{ saleResult.total | currency }}
                    </span>
                </div>
            </template>
            <div
                class="con-content input-number-simple d-flex flex-column justify-content-center px-4"
            >
                <div class="py-2">
                    <strong class="h4"> Ingresa el monto con que paga </strong>
                </div>
                <div class="d-flex">
                    <div>
                        <input-money-component-2
                            v-model="moneyIngress"
                            autofocus
                            @enter="onEnterMoneyIngress"
                        />
                    </div>
                    <vs-button
                        :disabled="!isValidMoneyIngress"
                        icon
                        circle
                        @click="onEnterMoneyIngress"
                    >
                        ✔️
                    </vs-button>
                </div>

                <div v-if="!isValidMoneyIngress" class="mt-3">
                    <span class="text-warning">
                        El dinero ingresado debe ser mayor a el valor de la
                        venta.
                    </span>
                </div>
            </div>
        </vs-dialog>
    </main>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

import InventoriesBarcodeScanner from "@/components/inventories/InventoriesBarcodeScanner.vue";
import InventoriesSearch from "@/components/inventories/InventoriesSearch.vue";
import OrderPrinter from "@/components/orders/OrderPrinter.vue";
import PaymentMethodsChecker from "@/components/orders/PaymentMethodsChecker.vue";
import ProductAnonymousButton from "@/components/products/ProductAnonymousButton.vue";
import ProductsBarcodeScanner from "@/components/products/ProductsBarcodeScanner.vue";
import ProductsSearch from "@/components/products/ProductsSearch.vue";
import ReferenceTableItem from "@/components/references/ReferenceTableItem.vue";
import SearchUser from "@/components/users/SearchUser.vue";
import UserByMerchantSelector2 from "@/components/users/UserByMerchantSelector2.vue";
import InputMoneyComponent2 from "@/components/utils/InputMoneyComponent2.vue";
import InputMoneyComponent from "@/components/utils/InputMoneyComponent.vue";
import ServiceSelector from "@/components/utils/ServiceSelector.vue";
import TooltipButton from "@/components/utils/TooltipButton.vue";

export default {
    name: "SalesNew",
    components: {
        SearchUser,
        OrderPrinter,
        TooltipButton,
        ProductsSearch,
        InventoriesSearch,
        InputMoneyComponent,
        ReferenceTableItem,
        ProductsBarcodeScanner,
        ProductAnonymousButton,
        InventoriesBarcodeScanner,
        PaymentMethodsChecker,
        UserByMerchantSelector2,
        InputMoneyComponent2,
        ServiceSelector
    },
    data: () => ({
        total: 0,
        subtotal: 0,
        valuePaid: 0,
        comments: "",
        references: [],
        sellerName: "",
        customerName: "",
        moneyIngress: 0,
        moneyBack: 0,
        sellerId: null,
        customerId: null,
        enableHelp: false,
        enableConfig: false,
        isOnboarding: false,
        showOrderPrinter: false,
        loaderInstance: null,
        enabledScanner: false,
        saleNeedSeller: false,
        saleNeedCustomer: false,
        saleNeedComments: false,
        enableHandlerKeys: true,
        referenceSelected: null,
        isOpenModalResult: false,
        isOpenModalSellers: false,
        saleNeedPartialPaid: false,
        isOpenModalComments: false,
        isOpenModalMoneyBack: false,
        isOpenModalCustomers: false,
        isOpenModalValuePaid: false,
        isOpenModalReferences: false,
        notVerifyAndDecreaseInventory: false,
        sourcePriceMerchant: false,
        paymentMethod: "cashonpickup",
        anonymousReferences: [],
        lastProductSelected: { name: "" },
        productTmp: {
            id: 68,
            sku: "ACUNU02",
            slug: "product",
            name: "Product Name",
            references: [],
            categories: []
        },
        saleResult: {
            id: 0,
            uuid: "",
            total: 20,
            createdAt: "2022-07-15T02:39:03.833Z",
            references: [],
            anonymousReferences: [],
            customer: {
                name: ""
            }
        }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor"]),
        ...mapState("commons", ["selectedMerchant", "isEnabledServiceSearch"]),
        isValidMoneyIngress() {
            return this.moneyIngress >= this.saleResult.total;
        }
    },
    watch: {
        saleNeedSeller(value) {
            if (value) {
                this.enabledScanner = false;
                this.isOpenModalSellers = true;
            }
        },
        saleNeedCustomer(value) {
            if (value) {
                this.enabledScanner = false;
                this.isOpenModalCustomers = true;
            }
        },
        saleNeedPartialPaid(value) {
            if (value) {
                this.enabledScanner = false;
                this.isOpenModalValuePaid = true;
            }
        },
        saleNeedComments(value) {
            if (value) {
                this.enabledScanner = false;
                this.isOpenModalComments = true;
            }
        }
    },
    created() {
        this.$watch(
            () => this.$route,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    location.reload();
                }
            },
            {
                deep: true
            }
        );
        this.isOnboarding = this.$route.path.includes("onboarding");
    },

    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        this.sourcePriceMerchant = this.$route.name !== "SalesNew";
        if (
            !(await verifyPageScope(
                this.sourcePriceMerchant
                    ? "sale:Create"
                    : "sale:CreateWithGlobalPrices",
                "/app/sales",
                true
            ))
        )
            return;
        this.enabledScanner = !this.isEnabledServiceSearch;
        if (this.enabledScanner) this.addHandlerKey();
    },
    beforeDestroy() {
        this.removeHandlerKey();
        this.enabledScanner = false;
    },
    methods: {
        ...mapActions("orders", ["createSale"]),
        ...mapMutations("commons", ["TOGGLE_IS_ENABLED_SERVICE_SEARCH"]),
        async createSaleComponent() {
            if (!this.anonymousReferences.length && !this.references.length) {
                return;
            }
            this.loaderInstance = this.$vs.loading({ type: "radius" });
            try {
                const sale = await this.createSale({
                    paymentMethod: this.paymentMethod,
                    merchantId: this.selectedMerchant.merchantId,
                    anonymousReferences: this.anonymousReferences,
                    sourcePriceMerchant: this.sourcePriceMerchant,
                    merchantType: this.selectedMerchant.merchantType,
                    verifyAndDecreaseInventory:
                        !this.notVerifyAndDecreaseInventory,
                    ...{
                        ...(this.saleNeedSeller
                            ? { sellerId: this.sellerId }
                            : null)
                    },
                    ...{
                        ...(this.saleNeedCustomer
                            ? { customerId: this.customerId }
                            : null)
                    },
                    ...{
                        ...(this.saleNeedComments
                            ? { comments: this.comments }
                            : null)
                    },
                    ...{
                        ...(this.saleNeedPartialPaid
                            ? { valuePaid: this.valuePaid }
                            : null)
                    },
                    references: this.references.map((item) => ({
                        id: item.id,
                        quantity: item.quantity,
                        appliedDiscountPerReference:
                            item.appliedDiscountPerReference
                    }))
                });

                this.saleResult = {
                    id: sale.id,
                    uuid: sale.uuid,
                    customerName: this.customerName,
                    customer: {
                        firstName: this.customerName
                    },
                    createdAt: sale.createdAt,
                    paymentStatus: sale.paymentStatus,
                    paymentMethod: sale.paymentMethod,
                    total: sale.total,
                    references: [...this.references],
                    anonymousReferences: [...this.anonymousReferences]
                };
                this.loaderInstance.close();
                this.removeHandlerKey();
                this.moneyIngress = this.saleNeedPartialPaid
                    ? this.valuePaid
                    : sale.total;
                this.playSound();
                if (
                    this.paymentMethod === "cashonpickup" &&
                    !this.saleNeedPartialPaid
                ) {
                    this.isOpenModalMoneyBack = true;
                    return;
                }
                this.isOpenModalResult = true;

                setTimeout(() => {
                    if (this.isOpenModalResult) {
                        this.isOpenModalResult = false;
                        this.closeResult();
                    }
                }, 60000);
            } catch (error) {
                this.loaderInstance.close();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            }
        },
        async selectProduct(product) {
            this.enabledScanner = false;
            this.productTmp = { ...product };
            if (product.references.length === 0) {
                await this.$swal.fire({
                    icon: "error",
                    title: "Producto invalido",
                    text: "Entra a los detalles del producto y verifica que tenga referencias"
                });
                window
                    .open(`/app/products/detail/${product.id}`, "_blank")
                    .focus();
            } else if (product.references.length === 1) {
                this.validatePush(product.references[0], product);
                this.isOpenModalReferences = false;
                this.referenceSelected = -1;
                this.enabledScanner = true;
            } else {
                this.productTmp = { ...product };
                this.referenceSelected = -1;
                this.isOpenModalReferences = true;
            }
        },
        selectComments() {
            this.isOpenModalComments = false;
        },
        selectValuePaid() {
            this.isOpenModalValuePaid = false;
        },
        calculateTotals() {
            const total = [
                ...this.references,
                ...this.anonymousReferences
            ].reduce(
                (a, b) =>
                    a +
                    (b.priceOffer - b.appliedDiscountPerReference) * b.quantity,
                0
            );
            if (!this.saleNeedPartialPaid) {
                this.valuePaid = +total;
            }
            this.total = +total;
            this.subtotal = this.total / (1 + 0.19);
        },
        validatePush(reference, product) {
            const index = this.references.findIndex(
                (item) => item.id === reference.id
            );

            if (index === -1) {
                this.references.unshift({
                    ...reference,
                    quantity: 1,
                    appliedDiscountPerReference: 0,
                    product: {
                        id: product.id,
                        slug: product.slug,
                        name: product.name,
                        sku: product.sku
                    }
                });
            } else {
                let references = [...this.references];
                references[index] = {
                    ...references[index],
                    quantity: references[index].quantity + 1
                };
                this.references = [...references];
            }
            this.calculateTotals();
        },
        pushReference() {
            if (this.referenceSelected < 0) return false;
            const reference =
                this.productTmp.references[this.referenceSelected];
            this.validatePush(reference, this.productTmp);
            this.isOpenModalReferences = false;
            this.referenceSelected = -1;
            this.enabledScanner = true;
        },
        selectReference(index) {
            if (index < 0) {
                return;
            }
            this.enabledScanner = false;
            this.referenceSelected = index;
            this.pushReference();
        },
        incrementReferenceSelect() {
            this.enabledScanner = false;
            if (!this.isOpenModalReferences) return;
            if (this.referenceSelected < 0) {
                this.referenceSelected = 0;
            } else if (
                this.referenceSelected <
                this.productTmp.references.length - 1
            ) {
                this.referenceSelected = this.referenceSelected + 1;
            }
        },
        decrementReferenceSelect() {
            this.enabledScanner = false;
            if (!this.isOpenModalReferences) return;
            if (this.referenceSelected < 0) {
                this.referenceSelected = 0;
            } else if (this.referenceSelected > 0) {
                this.referenceSelected = this.referenceSelected - 1;
            }
        },

        handleKey(event) {
            const actions = {
                ArrowDown: this.onDown,
                ArrowUp: this.onUp,
                Escape: this.onEscape,
                Enter: this.onEnter
            };
            const fun = actions[event.key] || function () {};
            fun();
        },
        onEscape() {
            this.closeReferenceModal();
        },
        onEnter() {
            this.enabledScanner = false;
            if (this.isOpenModalReferences) {
                return this.pushReference();
            }
            if (
                !this.isOpenModalCustomers &&
                !this.isOpenModalReferences &&
                !this.isOpenModalSellers
            ) {
                this.createSaleComponent();
            }
        },
        onUp() {
            if (this.isOpenModalReferences) {
                return this.decrementReferenceSelect();
            }
            if (
                !this.isOpenModalCustomers &&
                !this.isOpenModalReferences &&
                !this.isOpenModalSellers
            ) {
                document
                    .getElementById("app-container")
                    .scrollIntoView({ behavior: "smooth" });
            }
        },
        onDown() {
            if (this.isOpenModalReferences) {
                return this.incrementReferenceSelect();
            }
            if (
                !this.isOpenModalCustomers &&
                !this.isOpenModalReferences &&
                !this.isOpenModalSellers
            ) {
                document.getElementById("app-container").scrollIntoView(false);
            }
        },
        changeQuantityReference({ appliedDiscountPerReference, id, quantity }) {
            const index = this.references.findIndex((item) => item.id == id);
            let references = [...this.references];
            references[index] = {
                ...references[index],
                quantity,
                appliedDiscountPerReference
            };
            this.references = [...references];
            this.calculateTotals();
        },
        changeQuantityAnonymousReference({
            appliedDiscountPerReference,
            priceOffer,
            product,
            quantity
        }) {
            const index = this.anonymousReferences.findIndex(
                (item) =>
                    item.priceOffer === priceOffer &&
                    item.product.name === product.name
            );
            const anonymousReferences = [...this.anonymousReferences];
            anonymousReferences[index] = {
                ...anonymousReferences[index],
                quantity,
                appliedDiscountPerReference
            };
            this.anonymousReferences = [...anonymousReferences];
            this.calculateTotals();
        },
        removeReference({ id }) {
            const index = this.references.findIndex((item) => item.id == id);
            let references = [...this.references];
            references.splice(index, 1);
            this.references = [...references];
            this.calculateTotals();
        },
        removeAnonymousReference({ priceOffer, product }) {
            const index = this.anonymousReferences.findIndex(
                (item) =>
                    item.priceOffer === priceOffer &&
                    item.product.name === product.name
            );
            const anonymousReferences = [...this.anonymousReferences];
            anonymousReferences.splice(index, 1);
            this.anonymousReferences = [...anonymousReferences];
            this.calculateTotals();
        },
        selectProductAnonymous({ priceOffer, product, quantity }) {
            const index = this.anonymousReferences.findIndex(
                (item) =>
                    item.priceOffer === priceOffer &&
                    item.product.name === product.name
            );
            if (index === -1) {
                this.anonymousReferences.push({
                    priceOffer,
                    quantity,
                    product
                });
            } else {
                const anonymousReferences = [...this.anonymousReferences];
                anonymousReferences[index] = {
                    ...anonymousReferences[index],
                    quantity: anonymousReferences[index].quantity + quantity
                };
                this.anonymousReferences = [...anonymousReferences];
            }
            this.calculateTotals();
        },
        selectSeller(seller) {
            this.sellerId = seller.id;
            this.sellerName = `${seller.firstName} ${seller.lastName}`;
            this.isOpenModalSellers = false;
        },
        selectCustomer(customer) {
            this.customerId = customer.id;
            this.customerName = `${customer.firstName} ${customer.lastName}`;
            this.isOpenModalCustomers = false;
        },
        closeReferenceModal() {
            this.isOpenModalReferences = false;
            this.enabledScanner = true;
        },
        playSound() {
            const sound = this.$sounds.get("cash");
            sound.volume(0.4);
            sound.play();
        },
        closeResult() {
            this.isOpenModalResult = false;
            this.paymentMethod = "cashonpickup";
            this.total = 0;
            this.subtotal = 0;
            this.enabledScanner = true;
            this.anonymousReferences = [];
            this.references = [];
            this.saleNeedSeller = false;
            this.saleNeedCustomer = false;
            this.saleNeedComments = false;
            this.showOrderPrinter = false;
            if (this.isOnboarding) {
                return this.$router.push("/onboarding/finish");
            }
        },
        removeHandlerKey() {
            document.removeEventListener("keydown", this.handleKey);
        },
        addHandlerKey() {
            document.addEventListener("keydown", this.handleKey);
        },
        onBlurSomeInput() {
            this.enabledScanner = true;
            this.addHandlerKey();
        },
        onFocusSomeInput() {
            this.enabledScanner = false;
            this.removeHandlerKey();
        },
        toggleIsEnabledServices() {
            if (this.isEnabledServiceSearch) {
                this.onBlurSomeInput();
            } else {
                this.onFocusSomeInput();
            }
            this.TOGGLE_IS_ENABLED_SERVICE_SEARCH();
        },
        onEnterMoneyIngress() {
            this.isOpenModalMoneyBack = false;
            this.isOpenModalResult = true;
            this.moneyBack = this.moneyIngress - this.saleResult.total;
        }
    }
};
</script>
